<template>
  <q-page padding @keyup.enter="uploadCsv">
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Ajuste de Comissões</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Gerenciar" to="/campaigns" />
        <q-breadcrumbs-el label="Ajuste de Comissões" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-card flat bordered>
      <q-card-section class="row q-col-gutter-md">
        <div class="col-6">
          <select-request
            :v-model="campaignSelect"
            outlined
            :endpoint="`redirect?app=ADVERTISER&path=/api/get/campaign`"
            option-label="name"
            option-value="id"
            label="Selecione a campanha"
            clearable
            formatLabelCampaign
            @update="(value) => (campaignSelect = value === null ? null : value.id)"
          />
        </div>
        <div class="col-6">
          <q-file outlined v-model="fileInput" @input="changeCSV" label="Arquivo (CSV)">
            <template v-slot:prepend>
              <q-icon name="attach_file" />
            </template>
          </q-file>
        </div>
        <div class="col-12">
          <q-banner rounded class="bg-warning text-white q-pa-md">
            <div class="text-h6">Atenção ao formato correto do arquivo!</div>
            O delimitador do arquivo deverá ser " ; " e deverá conter as seguintes colunas: ID Conversão, Valor
            Conversão, Comissão Afilio e Comissão Afiliado. Em caso de dúvidas, você pode fazer o download do nosso
            modelo <a href="#" @click="directToFile()">clicando aqui</a>
          </q-banner>
        </div>
        <div class="col-6"></div>
      </q-card-section>
    </q-card>

    <q-card flat bordered v-if="data.length > 0" class="q-mt-md">
      <q-card-actions vertical align="center">
        <q-btn
          unelevated
          class="full-width"
          label="Ajustar"
          color="positive"
          no-caps
          style="max-width: 500px"
          @click="uploadCsv"
        />
      </q-card-actions>
      <q-card-section>
        <q-table flat :data="data" hide-pagination :pagination="paginationCustom" :columns="columns" />
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';

export default {
  name: 'CommissionAdjustments',
  components: { SelectRequest },
  data() {
    return {
      paginationCustom: { rowsPerPage: 99999999999 },
      fileInput: null,
      campaignSelect: null,
      data: [],
      columns: []
    };
  },
  methods: {
    directToFile() {
      window.open('https://afilio-v3.s3.amazonaws.com/Template_Ajuste_Comissao.csv', '_blank');
    },
    uuidV4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    async uploadCsv() {
      try {
        this.onLoading(true);

        let renameTo = `comission/conversor/adjustComission_${this.campaignSelect}_${
          this.user.user_id
        }_${this.$moment().format('YYYY_MM_DD')}_${this.$moment().format('H_I_S')}_${this.uuidV4()}.csv`;

        let form = new FormData();
        form.append('file', this.fileInput);
        form.append('file_rename', renameTo);
        form.append('bucket', 's3AdjustCommission');

        const { status } = await this.$http.post('/v2/upload/file', form);
        if (status === 200) {
          this.fileInput = null;
          this.campaignSelect = null;

          this.showInfoDialog(
            'Arquivo enviado para processamento. Após finalizado, será encaminhado um email para o responsável pela campanha.'
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    changeCSV(e) {
      let readCsv = new FileReader();
      readCsv.readAsText(e);

      readCsv.onload = () => {
        let csvList = readCsv.result.split('\n');
        let columns = csvList[0].split(';');

        let i = 0;
        columns.forEach((element) => {
          this.columns.push({
            name: element,
            label: element,
            field: element,
            align: 'left',
            index: i
          });
          i++;
        });

        let dataFormat = csvList.filter((item) => item != csvList[0]);

        dataFormat.forEach((el) => {
          if (el !== '') {
            let element = el.split(';');
            let data = {};

            this.columns.forEach((itemColum) => {
              data[itemColum.label] = element[itemColum.index];
            });

            this.data.push(data);
          }
        });
      };
    }
  }
};
</script>

<style></style>
