import { render, staticRenderFns } from "./CommissionAdjustments.vue?vue&type=template&id=4baa802e"
import script from "./CommissionAdjustments.vue?vue&type=script&lang=js"
export * from "./CommissionAdjustments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QToolbar,QToolbarTitle,QBreadcrumbs,QBreadcrumbsEl,QSeparator,QCard,QCardSection,QFile,QIcon,QBanner,QCardActions,QBtn,QTable});
